<!-- eslint-disable comma-dangle -->
<template>
  <div class="container grey lighten-5">
    <!-- <v-row> -->
    <v-stepper v-model="e13" vertical>
      <div class="createAcct">
        <h1>Create Account</h1>
      </div>
      <v-stepper-step step="1" :complete="e13 > 1">
        <h4>Company Details</h4>
        <small>Fields marked with <sup>*</sup> are mandatory</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="grey lighten-5" class="mb-12">
          <v-form ref="companydetailform" v-model="valid" fast-fail>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="company_name"
                    label="Company Name *"
                    counter="100"
                    :rules="companyNameRules"
                    hint="Must be at least 4 character long"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="tinName"
                    label="Tin *"
                    counter="13"
                    :rules="tinNameRules"
                    hint="Must be 13 character long"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="mainBusinessLineId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="mainBusItems1"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Main Line Of Business *"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-select
                    v-model="secondBusinessLineId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="secBusItems2"
                    label="Second Line Of Business "
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-select
                    v-model="companyOwnershipId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="companyItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Company Ownership type *"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="business_email"
                    :rules="emailRules"
                    label="Business E-mail*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="business_phone"
                    :rules="phoneRules"
                    :counter="11"
                    label="Phone Number *"
                    maxlength="11"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="CAC_registration_number"
                    :counter="11"
                    :rules="cacRules"
                    label="C.A.C. Registration Number *"
                    hint="Must be at least 8 character long"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date_of_incorporation"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_of_incorporation"
                        label="Date of Incorperation *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_of_incorporation" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date_of_incorporation)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="residenceStateId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="residentItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Residence State(HQ) *"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="company_address"
                    :rules="addressRules"
                    label="Company Address *"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <v-btn
          color="primary rounded-0"
          @click="firstStepProceed()"
        >Continue</v-btn>
      </v-stepper-content>

      <v-stepper-step step="2" :complete="e13 > 2">
        <h4>Contact Details</h4>
      </v-stepper-step>

      <v-stepper-content step="2" complete>
        <v-card color="grey lighten-4" class="mb-12">
          <v-form ref="contactdetailform" v-model="valid" fast-fail>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="first_name"
                    label="First name *"
                    :rules="firstNameRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="middle_name"
                    label="Middle name"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="surname"
                    label="Surname *"
                    :rules="surnameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="genderId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="genderItems"
                    :rules="[(v) => !!v || 'Gender is required']"
                    label="Select Gender *"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="email"
                    label="Email *"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="phone"
                    label="Phone *"
                    :rules="phoneRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="dob"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dob"
                        label="Date of Birth *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dob" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(dob)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-select
                    v-model="stateOfResidenceId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="residentItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="State of Residence*"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="lgaOfResidenceId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="lgaOfResidenceItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="LGA of Residence *"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="residence_address"
                    :rules="addressRules"
                    label="Residential Address *"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="white ma-0" md="12"> Government Issued ID </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="meansOfIdId"
                    return-object
                    item-text="title"
                    item-value="id"
                    :items="meansOfIdItems"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Means of Identification *"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="IDNumber"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="ID Number *"
                    hint="ID Number"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="white ma-0" md="12"> Next of Kin (NOK)</v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="nok_first_name"
                    :rules="firstNameRules"
                    label="NOK First name *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="nok_surname"
                    :rules="surnameRules"
                    label="NOK Surname *"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="nok_email"
                    :rules="emailRules"
                    label="NOK Email *"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="nok_phone"
                    :rules="phoneRules"
                    label="NOK Phone *"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <v-btn text @click="backtoFirstStep">Back</v-btn>
        &nbsp;
        <v-btn
          color="primary rounded-0"
          :disabled="signupBtnStatus"
          @click="createAccount"
        >{{ signupBtnText }}</v-btn>
      </v-stepper-content>
    </v-stepper>
    <v-snackbar v-model="snackbar">
      {{ snackbartext }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- </v-row> -->
  </div>
</template>

<style scoped>
h1 {
  font-size: 30px !important;
  font-weight: bolder;
}

.createAcct {
  height: 65px !important;
  padding: 15px;
  border-left: 5px solid #329332;
}
</style>

<script>
//import Uploads from '@/components/ui/landing/signup/Uploads.vue'
//import myServices from '../assets/services/myservices'
import serv from '../services/HTTPService'
import tts from '../services/TTSService'

export default {
  components: {
    //Uploads
  },
  data() {
    return {
      // eslint-disable-next-line comma-dangle
      pitch: 1,
      rate: 1,
      synth: window.speechSynthesis,

      signupBtnStatus: false,
      signupBtnText: 'Submit',
      snackbar: false,
      snackbartext: '',
      e13: 1,
      date_of_incorporation: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      modal: false,
      valid: true,
      company_name: '',
      companyNameRules: [
        (v) => !!v || 'Company Name is required',
        (v) =>
          (v && v.length > 3) ||
          'Company Name must be greater than 3 characters'
      ],

      tinName: '',
      tinNameRules: [
        (v) => !!v || 'Tin Name is required',
        (v) => /^\d{8}-\d{4}$/.test(v) || 'TIN must be valid 16 charaters [e.g: 12345678-0001]',
        (v) => (v && v.length <= 13) || 'Tin must be 13 character long'
      ],

      business_phone: '',
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],

      business_email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      CAC_registration_number: '',
      cacRules: [
        (v) => !!v || 'CAC Number is required',
        (v) => (v && v.length <= 11) || 'Name must be less than 11 characters'
      ],
      company_address: '',
      addressRules: [
        (v) => !!v || 'Commpany Address is required',
        (v) =>
          (v && v.length <= 35) ||
          'Commpany Address must be less than 35 characters'
      ],

      first_name: '',
      firstNameRules: [(v) => !!v || 'First Name is required'],
      middle_name: '',
      surname: '',
      surnameRules: [(v) => !!v || 'Surname is required'],
      phone: '',
      email: '',
      dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      stateOfResidenceId: 0,
      lgaOfResidenceId: 0,
      lgaOfResidenceItems: [],
      residence_address: '',

      select: null,
      residenceStateId: 0,
      residentItems: [],
      alllga: [],

      mainBusinessLineId: 0,
      mainBusItems1: [],
      secondBusinessLineId: 0,
      secBusItems2: [],
      companyOwnershipId: 0,
      companyItems: [],
      genderId: 0,
      genderItems: [
        {
          id: 1,
          title: 'Male'
        },
        {
          id: 2,
          title: 'Female'
        }
      ],
      meansOfIdId: 0,
      IDNumber: '',
      meansOfIdItems: [
        {
          id: 1,
          title: 'Permanent Voter Card'
        },
        {
          id: 2,
          title: 'National Driver License'
        },
        {
          id: 3,
          title: 'International Passport'
        },
        {
          id: 4,
          title: 'National ID Card'
        }
      ],
      nok_first_name: '',
      nok_surname: '',
      nok_email: '',
      nok_phone: ''
    }
  },
  watch: {
    stateOfResidenceId: {
      handler(newvalue) {
        this.lgaOfResidenceItems = this.alllga.filter(
          (lga) => lga.state_id === this.stateOfResidenceId.id
        )
      },
      immediate: true
    },
    mainBusinessLineId: {
      handler(newvalue) {
        this.secondBusinessLineId = 0
        this.secBusItems2 = this.mainBusItems1.filter(
          (businessline2) => businessline2.id !== this.mainBusinessLineId.id
        )
        //////console.log('all the lga is ', this.statelgas)
      },
      immediate: true
    }
  },
  created() {
    //const resValue = myServices.getCreateAccountData()
    const formOptionsUrl = serv.getGuestUrl('/formOptions/createAccount')
    const resValue = serv.makeRequest(formOptionsUrl, 'GET')

    serv.clearSession()

    resValue.then((result) => {
      //console.log('our result is ', result)
      if (result.status === 200) {
        this.mainBusItems1 = result.data.main_line_of_business_rf
        this.secBusItems2 = result.data.main_line_of_business_rf
        this.companyItems = result.data.company_ownership_type_rf
        this.residentItems = result.data.residence_state_rf
        this.alllga = result.data.residence_lga_rf

        /* this.signupBtnText = 'Account Created'

        this.snackbartext = 'Registration Successful, Login to continue'
        this.snackbar = true
        
        setTimeout(() => {
          this.signupBtnStatus = false
          this.signupBtnText = 'Create Account'
          this.$router.push({ name: 'signin-page' })
        }, 5000)*/
      } else {
        /* this.snackbartext = result.message
        this.snackbar = true
        this.signupBtnStatus = false
        this.signupBtnText = 'Create Account' */
      }
    })
  },
  methods: {
    backtoFirstStep: function () {
      this.e13 = 1
      window.scrollTo(0, 0)
    },
    firstStepProceed: function () {
      if (this.$refs.companydetailform.validate()) {
        this.e13 = 2
      }
      //this.postCreateAccount()
    },

    createAccount: function () {
      if (this.$refs.contactdetailform.validate()) {
        const sendData = {
          business_email: this.business_email,
          business_phone: this.business_phone,
          company_name: this.company_name,
          CAC_registration_number: this.CAC_registration_number,
          date_of_incorporation: this.date_of_incorporation,
          TIN: this.tinName,
          company_ownership_type_rf: this.companyOwnershipId.id,
          HQ_residence_state_rf: this.residenceStateId.id,
          company_address: this.company_address,
          first_name: this.first_name,
          middle_name: this.middle_name,
          surname: this.surname,
          gender_rf: this.genderId.id,
          dob: this.dob,
          phone: this.phone,
          email: this.email,
          residence_state_rf: this.residenceStateId.id,
          residence_lga_rf: this.lgaOfResidenceId.id,
          residence_address: this.residence_address,
          means_of_identification_rf: this.meansOfIdId.id,
          IDNumber: this.IDNumber,
          nok_first_name: this.nok_first_name,
          nok_surname: this.nok_surname,
          nok_email: this.nok_email,
          nok_phone: this.nok_phone,
          main_line_of_business_rf: this.mainBusinessLineId.id,
          second_line_of_business_rf: this.secondBusinessLineId.id
        }

        this.signupBtnStatus = true
        this.signupBtnText = 'Submitting...'
        //console.log('The data ', sendData)
        const authenUrl = serv.getAuthenUrl('/members/createAccount')
        const resValue = serv.makeRequest(authenUrl, 'POST', sendData)

        resValue.then((result) => {
          //console.log('our result is ', result)
          if (result.status === 200) {
            this.postCreateAccount(result.data)
          } else if (result.status === 409) {
            this.signupBtnStatus = false
            this.signupBtnText = 'Submit'
            this.snackbar = true
            this.snackbartext = 'Error: Multiple Registration!'
            // voice
          } else {
            this.signupBtnStatus = false
            this.signupBtnText = 'Submit'
            this.snackbar = true
            this.snackbartext = result.data.error
          }
          //////console.log('our response value is ', result)
        })
      }
    },
    postCreateAccount: function (data) {
      this.signupBtnText = 'Submitted'
      this.snackbar = true
      this.snackbartext = 'Data submited! Please upload your documents'
      //call voice
      const speech =
        'You have completed your data entry activity. The next step is to upload your documents'

      serv.setSessionItem('membershipid', data.membership_id)
      serv.setSessionItem('processStatus', 'new')
      const storedUserData = {
        membership_id: data.membership_id
      }

      //console.log('first',this.storedUserData )
      serv.setSessionJson('storedUserData', storedUserData)
      tts.speak(speech)
      setTimeout(() => {
        this.signupBtnStatus = false
        this.$router.push({
          name: 'uploads',
          params: { membershipid: data.membership_id }
        })
      }, 5000)
    }
  }
  // methods: {
  //   validate () {
  //     this.$refs.form.validate()
  //   }
  // }

  // eslint-disable-next-line comma-dangle
}
</script>